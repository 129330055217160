var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-chart',{attrs:{"data":_vm.data,"forceFit":true,"height":_vm.height,"padding":0,"scale":_vm.scale,"width":_vm.width}},[_c('v-tooltip'),_c('v-interval',{attrs:{"shape":['liquid-fill-gauge'],"tooltip":[
        'transfer*value',
        function (transfer, value) {
          return {
            name: transfer,
            value: value,
          };
        } ],"v-style":{
        lineWidth: 10,
        opacity: 0.75
      },"color":"","position":"transfer*value"}}),_vm._l((_vm.data),function(row,index){return _c('v-guide',{key:index,attrs:{"content":row.value + '%',"position":{
        gender: row.transfer,
        value: 45
      },"top":true,"v-style":{
        fontSize: 100,
        textAlign: 'center',
        opacity: 0.75,
      },"type":"text"}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }